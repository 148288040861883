import Section from "@src/components/containers/section"
import SectionContent from "@src/components/containers/section-content"
import Columns from "@src/components/grid/columns"
import Rows from "@src/components/grid/rows"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Heading from "@src/components/content/heading"
import Content from "@src/components/grid/content"
import Copy from "@src/components/content/copy"

const PricingFeatures = () => {
  const pricingFeaturesContent = [
    {
      img: (
        <StaticImage src="../../images/pricing/pricing__0001_automated_click_analysis.png" />
      ),
      heading: "Automated Click Analysis",
      content:
        "<p>If a click shows sign of being fake, fraudulent or non-converting, we’ll block it from your ads.</p>",
    },
    {
      img: (
        <StaticImage src="../../images/pricing/pricing__0003_multiple_ad_networks.png" />
      ),
      heading: "Over 15 Ad Networks",
      content:
        "<p>We support Google, Bing, Twitter, LinkedIn, Meta, Reddit, TikTok, Yandex, Naver and many more.</p>",
    },
    {
      img: (
        <StaticImage src="../../images/pricing/pricing__0000_feedback_optimization.png" />
      ),
      heading: "Feedback Optimisation",
      content:
        "<p>High-quality traffic behavior data is fed back into acquisition channels to optimise positive buying signals.</p>",
    },
    {
      img: (
        <StaticImage src="../../images/pricing/pricing__0004_total_transparency.png" />
      ),
      heading: "Total Transparency",
      content:
        "<p>All click activity from every ad channel is stored as first-party data for further analysis.</p>",
    },
    {
      img: (
        <StaticImage src="../../images/pricing/pricing__0005_no_false_positives.png" />
      ),
      heading: "No False Positives",
      content:
        "<p>Our machine learning approach dramatically diminishes the chances of false positives, allowing you to advertise with confidence.</p>",
    },
    {
      img: (
        <StaticImage src="../../images/pricing/pricing__0002_agency_ready.png" />
      ),
      heading: "Agency Ready",
      content:
        "<p>Many of our clients are digital agencies. We support multi-account and multi-campaign nested administration and reporting.</p>",
    },
  ]

  return (
    <Section>
      <SectionContent paddingBot="xs" paddingTop="lg">
        <Rows gap="xxl">
          <Columns count="1">
            <Heading
              alignment="center"
              className="mb-md lap-mb-none lap-mx-xxl"
              level={2}
            >
              What You’ll Get With Lunio
            </Heading>
          </Columns>
          <Columns count="2">
            {pricingFeaturesContent.map(({ img, heading, content }, index) => (
              <Columns
                alignment="center"
                className="mb-md grid__columns--pricing-feature"
                key={index}
              >
                <div className="mr-md tab-mr-none grid__columns--pricing-feature__img">
                  {img}
                </div>
                <Content paddingLeft="md">
                  <Heading level={4}>{heading}</Heading>
                  <Copy contentToParse={content} />
                </Content>
              </Columns>
            ))}
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default PricingFeatures
