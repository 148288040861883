import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../components/interactive/button"
import Heading from "../components/content/heading"
import Section from "../components/containers/section"
import SectionContent from "../components/containers/section-content"
import Rows from "../components/grid/rows"
import Columns from "../components/grid/columns"
import { Content, Footer } from "../components/grid"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import Copy from "../components/content/copy"
import SectionBackground from "../components/containers/section-background"
import Card from "../components/containers/card"
import Dropdown from "../components-smart/interactive/dropdown"
import PricingFeatures from "@src/sections/pricing/pricing-features"
import PricingCTA from "@src/sections/pricing/pricing-cta"
import ClearbitConverisonForm from "@src/components-smart/forms/clearbit-conversion-form"
import HeroForm from "@src/sections/home/hero-form"

function PricingPage({ data, location }) {
  const { heading, seo, pricingBoxes, addons, logoRow, faq, testimonials } =
    data.strapiPagePricing

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section wave="shield" theme="dark">
          <SectionBackground>
            <div className="absolute width--full height--full hero-image hero-image--pricingbg" />
          </SectionBackground>
          <SectionContent hero paddingTop="md" paddingBot="none">
            <Rows gap="none" className="mb-md tab-mb-lg">
              <Columns count="2">
                <Content paddingLeft="none" paddingRight="none">
                  <Heading className="mt-xl" alignment="left" level={1}>
                    {heading.headingText}
                  </Heading>
                  <Copy>
                    <p>{heading.postHeadingText}</p>
                  </Copy>
                  <Footer className="tab-mr-xxl">
                    <div className="flex" style={{ columnGap: 16 }}>
                      <StaticImage
                        width={100}
                        src="../images/homepage/badges/g2-implementable-fall.png"
                      />
                      <StaticImage
                        width={100}
                        src="../images/homepage/badges/g2-leader-winter.png"
                      />
                      <StaticImage
                        width={100}
                        src="../images/homepage/badges/g2-easiest-summer.png"
                      />
                    </div>
                  </Footer>
                </Content>
                <Content className="tab-mx-lg tab-mt-xl">
                  <Card className="relative pb-lg" styleType="white">
                    <Columns
                      alignment="center"
                      className="pricing-images__container mt-xs"
                      count={2}
                    >
                      <p className="h2 pl-sm">Request Pricing</p>
                      <div className="ml-sm">
                        <StaticImage
                          src="../images/pricing/nodepad.png"
                          className="pricing-images__notepad"
                        />
                      </div>
                    </Columns>
                    <HeroForm formId="a8b800a4-fce5-42ed-a3ec-ae668f566824" />
                  </Card>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingBot="xs" paddingTop="lg">
          <Rows gap="xxl">
            <Columns count="1" className="mb-md">
              <Content gap="xxl" className="tab-pt-lg">
                <Heading alignment="center">
                  {faq.heading.primaryHeading}
                </Heading>
                <Dropdown defaultSelected={1} content={faq.tabs} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <PricingFeatures />

      <PricingCTA
        titleText="How Much Does Lunio Cost?"
        contentText=" Get your own customized proposal! Lunio's pricing model
        depends on a few factors specific to your needs, so we'll need
        to get in touch to give you accurate pricing info. Fill out
        the form to get started."
        buttonLink="https://lp.lunio.ai/request-pricing/"
      />
    </Main>
  )
}

export const query = graphql`
  query PricingPageQuery {
    strapiPagePricing {
      heading {
        headingText
        postHeadingText
      }
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      pricingBoxes {
        boxText
        boxTitle
        button {
          link
          text
        }
        featureTitle
        features {
          featureText
        }
      }
      addons {
        heading {
          preHeading
          primaryHeading
        }
        tabs {
          copy: faqTabText
          title: faqTabTitle
        }
      }
      logoRow {
        logos {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alternativeText
        }
      }
      faq {
        heading {
          preHeading
          primaryHeading
        }
        tabs {
          copy: faqTabText
          title: faqTabTitle
        }
      }
      testimonials {
        testimonials {
          authorText
          link
          btnHex
          btnHexHover
          testimonialQuoteText
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        testimonialsTitle {
          preHeading
          primaryHeading
        }
      }
    }
  }
`

export default PricingPage
